var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MODE_DISPLAY } from "../../constant";
import { Button } from "./button.class";
var ButtonLight = /** @class */ (function (_super) {
    __extends(ButtonLight, _super);
    function ButtonLight() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /** @override */
    ButtonLight.prototype.onPress = function (time, watch) {
        var element = document.getElementById("watch-".concat(watch.index));
        if (watch.mode === MODE_DISPLAY.LIGHT) {
            element.className = "watch dark";
            watch.mode = MODE_DISPLAY.DARK;
            watch.digits.get('lightButton').innerHTML = MODE_DISPLAY.LIGHT;
        }
        else {
            element.className = "watch light";
            watch.mode = MODE_DISPLAY.LIGHT;
            watch.digits.get('lightButton').innerHTML = MODE_DISPLAY.DARK;
        }
    };
    return ButtonLight;
}(Button));
export { ButtonLight };
