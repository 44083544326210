var Matrix = /** @class */ (function () {
    function Matrix(rows, columns, coord) {
        var _this = this;
        this.rows = Math.max(rows, 1);
        this.columns = Math.max(columns, 1);
        this.coord = new Array(this.rows).fill([]).map(function () { return new Array(_this.columns).fill(0); });
        if (coord) {
            this.coord = coord;
        }
    }
    /**
     * Returns the coordinate value at a defined position
     * @param row - Index of row
     * @param col - Index of column
     * @returns The position value at a defined point
     */
    Matrix.prototype.at = function (row, col) {
        return this.coord[row][col];
    };
    /**
     * Reset the matrix to zero
     */
    Matrix.prototype.reset = function () {
        this.coord = this.coord.map(function (row) { return row.map(function () { return 0; }); });
    };
    /**
     * Returns the rotation matrix from rotation angle, 2 affected axes
     * @param rad - Rotation angle (rad)
     * @param axes1 - Affected axe 1
     * @param axes2 - Affected axe 2
     * @returns The rotation matrix
     * @example toRotationMatrix(0.5, 0, 1) will returns a rotation matrix around axe Z with 0.5rad. So 0 and 1 are indexes of axe X and axe Y.
     * Axe x = index 0
     * Axe y = index 1
     * Axe z = index 2
     */
    Matrix.prototype.toRotationMatrix = function (rad, axes1, axes2) {
        for (var i = 0; i < this.rows; i++) {
            for (var j = 0; j < this.columns; j++) {
                if (i === axes1 && j === axes1) {
                    this.coord[i][j] = Math.cos(rad);
                }
                if (i === axes2 && j === axes1) {
                    this.coord[i][j] = Math.sin(rad);
                }
                if (i === axes1 && j === axes2) {
                    this.coord[i][j] = -Math.sin(rad);
                }
                if (i === axes2 && j === axes2) {
                    this.coord[i][j] = Math.cos(rad);
                }
            }
        }
        return this;
    };
    ;
    /**
     * Transform a vector to scale matrix
     * @param vector Vector to be transformed
     * @returns Scale matrix
     */
    Matrix.prototype.toScaleMatrix = function (vector) {
        var array = vector.toArray();
        var size = array.length;
        var matrix = new Matrix(size, size);
        for (var i = 0; i < size; i++) {
            for (var j = 0; j < size; j++) {
                if (i === j) {
                    matrix.coord[i][j] = array[i];
                }
                else {
                    matrix.coord[i][j] = 0;
                }
            }
        }
        return matrix;
    };
    ;
    /**
     * Transform a vector to a matrix
     * @param vector Vector to be transformed
     * @returns Matrix
     */
    Matrix.prototype.fromVectorToMatrix = function (vector) {
        var array = vector.toArray();
        var size = array.length;
        var matrix = new Matrix(size, 1);
        for (var i = 0; i < size; i++) {
            matrix.coord[i][0] = array[i];
        }
        return matrix;
    };
    ;
    /**
     * Transform a vector to a translation matrix
     * @param vector - Vector to be transformed
     * @returns Translation matrix
     */
    Matrix.prototype.fromVectorToTranslationMatrix = function (vector) {
        var array = vector.toArray();
        var size = array.length;
        var matrix = new Matrix(size, size);
        for (var i = 0; i < size; i++) {
            for (var j = 0; j < size; j++) {
                if (i == j) {
                    matrix.coord[i][j] = 1;
                }
                else {
                    matrix.coord[i][j] = 0;
                }
            }
        }
        for (var i = 0; i < size; i++) {
            matrix.coord[i][size - 1] = array[i];
        }
        return matrix;
    };
    /**
     * Compare to the matrix in parameter
     * @param mat - Matrix to be compared
     * @returns True if these 2 matrix are equals
     */
    Matrix.prototype.equals = function (mat) {
        // Reduce on rows -> reduce on columns -> if a value != then false!
        return (this.rows === mat.rows && this.columns === mat.columns)
            && this.coord.reduce(// Rows
            function (eql, row, i) { return eql && row.reduce(// Columns (real values)
            function (eql2, val, j) { return eql2 && mat.at(i, j) === val; }, eql); }, true);
    };
    /**
     * Transform to an identity matrix
     * @returns The identity matrix
     */
    Matrix.prototype.setAsIdentity = function () {
        var _this = this;
        if (this.rows !== this.columns)
            throw new Error("Dimension error! The matrix isn't squared!");
        this.coord.forEach(function (row, i) {
            row.forEach(function (c, j) {
                _this.coord[i][j] = i === j ? 1 : 0;
            });
        });
        return this;
    };
    /**
     * Transform to an identity matrix with a defined dimension
     * @param dimension - Dimension of the identity matrix returned
     * @returns The identity matrix
     */
    Matrix.prototype.identity = function (dimension) {
        if (dimension < 1)
            throw Error('Dimension error! Matrix dimension must be positive.');
        return new Matrix(dimension, dimension).setAsIdentity();
    };
    /**
     * Calculate the value of determinant of matrix
     * @returns The determinant of matrix
     */
    Matrix.prototype.determinant = function () {
        if (this.rows !== this.columns)
            throw new Error("Dimension error! The matrix isn't squared!");
        if (this.rows === this.columns && this.columns === 1) {
            return this.coord[0][0];
        }
        var det = 0;
        var sign = 1;
        if (this.rows === 2) {
            det = this.coord[0][0] * this.coord[1][1] - this.coord[1][0] * this.coord[0][1];
        }
        else {
            for (var i = 0; i < this.rows; i++) {
                var minor = this.getCofactor(0, i);
                det += sign * this.at(0, i) * minor.determinant();
                sign = -sign;
            }
        }
        return det;
    };
    /**
     * Add a new column to current matrix
     * @returns The new matrix with a new column
     */
    Matrix.prototype.addAColumn = function () {
        return new Matrix(this.rows, this.columns + 1, this.coord);
    };
    /**
     * Add a new row to current matrix
     * @returns The new matrix with a new row
     */
    Matrix.prototype.addARow = function () {
        return new Matrix(this.rows + 1, this.columns, this.coord);
    };
    /**
     * Get the cofactor matrix
     * @link https://www.cuemath.com/algebra/cofactor-matrix/
     * @param row - Row index
     * @param col - Column index
     * @returns The cofactor matrix
     */
    Matrix.prototype.getCofactor = function (row, col) {
        return new Matrix(this.rows - 1, this.columns - 1, this.coord
            .filter(function (v, i) { return i !== row; }) // Remove the unnecessary row
            .map(function (c) { return c.filter(function (v, i) { return i !== col; }); }));
    };
    /**
     * Transpose a matrix
     * @returns The transpose matrix
     */
    Matrix.prototype.transpose = function () {
        var _this = this;
        return new Matrix(this.columns, this.rows, new Array(this.columns).fill([])
            .map(function (row, i) { return new Array(_this.rows).fill(0).map(function (c, j) { return _this.at(j, i); }); }));
    };
    /**
     * Calculate the inverse matrix
     * @returns The inverse matrix
     */
    Matrix.prototype.inverse = function () {
        var _this = this;
        if (this.rows !== this.columns)
            throw new Error("Dimension error! The matrix isn't squared!");
        var det = this.determinant();
        if (det === 0)
            throw new Error("Determinant is 0, can't compute inverse.");
        // Get cofactor matrix: i.e. for each matrix value, get the cofactor's determinant
        var cofactorMatrix = new Matrix(this.rows, this.columns, this.coord.map(function (row, i) { return row.map(function (val, j) {
            var sign = Math.pow(-1, i + j);
            return sign * _this.getCofactor(i, j).determinant();
        }); }));
        // Transpose it
        var transposedCofactor = cofactorMatrix.transpose();
        // Compute inverse of transposed / determinant on each value
        return new Matrix(this.rows, this.columns, this.coord.map(function (row, i) { return row.map(function (val, j) { return transposedCofactor.at(i, j) / det; }); }));
    };
    ;
    /**
     * Multiple with the matrix in parameter
     * @param mat - Matrix to be multipled with
     * @returns The result of multiple matrix calculation
     */
    Matrix.prototype.multiple = function (mat) {
        var _this = this;
        if (this.columns !== mat.rows)
            throw new Error("Dimension error! The operand matrix must have the same number of rows as 'this' matrix columns!");
        var resMatrix = new Matrix(this.rows, mat.columns);
        resMatrix.coord = resMatrix.coord.map(function (row, i) {
            return row.map(function (val, j) {
                return _this.coord[i].reduce(function (sum, elm, k) { return sum + (elm * mat.at(k, j)); }, 0);
            });
        });
        return resMatrix;
    };
    ;
    return Matrix;
}());
export { Matrix };
