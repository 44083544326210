import { Point } from "./point.class";
var Vector = /** @class */ (function () {
    function Vector(point) {
        this.x = point.x;
        this.y = point.y;
        this.z = 1;
    }
    /**
     * Transform the vector to an array
     * @returns The array transformed from vector
     */
    Vector.prototype.toArray = function () {
        return [this.x, this.y, this.z];
    };
    /**
     * Transform the vector to a point
     * @returns The point transformed from vector
     */
    Vector.prototype.toPoint = function () {
        var p = new Point(0, 0);
        p.x = this.x;
        p.y = this.y;
        return p;
    };
    return Vector;
}());
export { Vector };
