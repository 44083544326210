var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MODE } from "../../constant";
import { Button } from "./button.class";
var ButtonMode = /** @class */ (function (_super) {
    __extends(ButtonMode, _super);
    function ButtonMode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /** @override */
    ButtonMode.prototype.onPress = function (time, watch) {
        switch (time.mode) {
            case MODE.DISABLED:
                time.mode = MODE.EDIT_HOUR;
                watch.blinkHour();
                break;
            case MODE.EDIT_HOUR:
                time.mode = MODE.EDIT_MINUTE;
                watch.blinkMinute();
                break;
            default:
                time.mode = MODE.DISABLED;
                watch.unBlink();
                break;
        }
    };
    return ButtonMode;
}(Button));
export { ButtonMode };
