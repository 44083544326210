import { DIGIT_TO_NAME, FORMAT, MODE_DISPLAY } from "../../constant";
import { Animation } from "../../lib/animation";
import { Point } from "../../lib/animation/src/class/point.class";
import { ButtonDisplay } from "../button/buttonDisplay.class";
import { ButtonIncrease } from "../button/buttonIncrease.class";
import { ButtonLight } from "../button/buttonLight.class";
import { ButtonMode } from "../button/buttonMode.class";
import { ButtonReset } from "../button/buttonReset.class";
import { Time } from "../time/time.class";
var Watch = /** @class */ (function () {
    function Watch(timezone, index) {
        this.buttonIncrease = new ButtonIncrease();
        this.buttonLight = new ButtonLight();
        this.buttonMode = new ButtonMode();
        this.buttonDisplay = new ButtonDisplay();
        this.buttonReset = new ButtonReset();
        this.index = index;
        this.timezone = timezone;
        this.time = new Time(timezone);
        this.mode = MODE_DISPLAY.LIGHT;
        this.digits = new Map();
        this.initDeg = 0;
        this.initScale = new Point(1, 1);
        this.init();
    }
    /**
     * Run the watch
     */
    Watch.prototype.run = function () {
        var _this = this;
        this.time.start(function () {
            _this.update();
        });
    };
    /**
     * Init all elements of the watch
     */
    Watch.prototype.init = function () {
        var parent = document.getElementById('list-watches');
        var watchElement = document.createElement('div');
        watchElement.id = "watch-".concat(this.index);
        watchElement.className = "watch ".concat(this.mode);
        var displayElement = document.createElement('div');
        displayElement.className = 'display';
        var ampmElement = document.createElement('div');
        ampmElement.className = 'ampm';
        ampmElement.innerHTML = this.time.format === FORMAT.GLOBAL ? '' : this.time.localType;
        var timezoneElement = document.createElement('span');
        timezoneElement.innerHTML = this.timezone;
        this.digits.set('ampm', ampmElement);
        var digitElement = document.createElement('div');
        digitElement.className = 'digits';
        var positions = [
            'h1', 'h2', ':', 'm1', 'm2', ':', 's1', 's2'
        ];
        for (var _i = 0, positions_1 = positions; _i < positions_1.length; _i++) {
            var str = positions_1[_i];
            if (str === ':') {
                var dotElement = document.createElement('div');
                dotElement.className = 'dots';
                digitElement.append(dotElement);
            }
            else {
                var posElement = document.createElement('div');
                for (var i = 1; i < 8; i++) {
                    var spanElement = document.createElement('span');
                    spanElement.className = "d".concat(i);
                    posElement.append(spanElement);
                }
                this.digits.set(str, posElement);
                digitElement.append(posElement);
            }
        }
        var hour = this.time.hour < 10 ? "0".concat(this.time.hour) : this.time.hour.toString();
        var minute = this.time.minute < 10 ? "0".concat(this.time.minute) : this.time.minute.toString();
        var second = this.time.second < 10 ? "0".concat(this.time.second) : this.time.second.toString();
        this.digits.get('h1').setAttribute('class', DIGIT_TO_NAME[parseInt(hour[0])]);
        this.digits.get('h2').setAttribute('class', DIGIT_TO_NAME[parseInt(hour[1])]);
        this.digits.get('m1').setAttribute('class', DIGIT_TO_NAME[parseInt(minute[0])]);
        this.digits.get('m2').setAttribute('class', DIGIT_TO_NAME[parseInt(minute[1])]);
        this.digits.get('s1').setAttribute('class', DIGIT_TO_NAME[parseInt(second[0])]);
        this.digits.get('s2').setAttribute('class', DIGIT_TO_NAME[parseInt(second[1])]);
        displayElement.append(ampmElement);
        displayElement.append(digitElement);
        watchElement.append(displayElement);
        watchElement.append(timezoneElement);
        var buttonContainerElement = document.createElement('div');
        buttonContainerElement.className = 'button-container';
        var increaseButton = document.createElement('button');
        increaseButton.innerHTML = 'increase';
        increaseButton.addEventListener('click', this.buttonIncrease.onPress.bind(this.buttonIncrease, this.time, this));
        var modeButton = document.createElement('button');
        modeButton.innerHTML = 'mode';
        modeButton.addEventListener('click', this.buttonMode.onPress.bind(this.buttonMode, this.time, this));
        var lightButton = document.createElement('button');
        lightButton.innerHTML = this.mode === MODE_DISPLAY.LIGHT ? MODE_DISPLAY.DARK : MODE_DISPLAY.LIGHT;
        lightButton.addEventListener('click', this.buttonLight.onPress.bind(this.buttonLight, this.time, this));
        var displayButton = document.createElement('button');
        displayButton.innerHTML = this.time.format;
        displayButton.addEventListener('click', this.buttonDisplay.onPress.bind(this.buttonDisplay, this.time, this));
        var scaleUpButton = document.createElement('button');
        scaleUpButton.innerHTML = 'scale up';
        scaleUpButton.addEventListener('click', this.scale.bind(this, 0.1, 0.1));
        var scaleDownButton = document.createElement('button');
        scaleDownButton.innerHTML = 'scale down';
        scaleDownButton.addEventListener('click', this.scale.bind(this, -0.1, -0.1));
        var resetButton = document.createElement('button');
        resetButton.innerHTML = 'reset';
        resetButton.addEventListener('click', this.buttonReset.onPress.bind(this.buttonReset, this.time, this));
        var rotateAroundSelfButton = document.createElement('button');
        rotateAroundSelfButton.innerHTML = 'rotate self';
        rotateAroundSelfButton.addEventListener('click', this.startRotateAroundSelf.bind(this));
        var rotateAroundButton = document.createElement('button');
        rotateAroundButton.innerHTML = 'rotate around';
        rotateAroundButton.addEventListener('click', this.startRotate.bind(this));
        this.digits.set('lightButton', lightButton);
        this.digits.set('displayButton', displayButton);
        buttonContainerElement.append(increaseButton);
        buttonContainerElement.append(modeButton);
        buttonContainerElement.append(lightButton);
        buttonContainerElement.append(displayButton);
        buttonContainerElement.append(scaleUpButton);
        buttonContainerElement.append(scaleDownButton);
        buttonContainerElement.append(rotateAroundSelfButton);
        buttonContainerElement.append(rotateAroundButton);
        buttonContainerElement.append(resetButton);
        watchElement.append(buttonContainerElement);
        parent.appendChild(watchElement);
        this.getCoordinate();
    };
    /**
     * Update all attributes of the watch
     */
    Watch.prototype.update = function () {
        var _a, _b, _c, _d, _e, _f;
        var hour = this.time.hour < 10 ? "0".concat(this.time.hour) : this.time.hour.toString();
        var minute = this.time.minute < 10 ? "0".concat(this.time.minute) : this.time.minute.toString();
        var second = this.time.second < 10 ? "0".concat(this.time.second) : this.time.second.toString();
        this.digits.get('ampm').innerHTML = this.time.format === FORMAT.GLOBAL ? '' : this.time.localType;
        (_a = this.digits.get('h1').classList).remove.apply(_a, DIGIT_TO_NAME);
        (_b = this.digits.get('h2').classList).remove.apply(_b, DIGIT_TO_NAME);
        (_c = this.digits.get('m1').classList).remove.apply(_c, DIGIT_TO_NAME);
        (_d = this.digits.get('m2').classList).remove.apply(_d, DIGIT_TO_NAME);
        (_e = this.digits.get('s1').classList).remove.apply(_e, DIGIT_TO_NAME);
        (_f = this.digits.get('s2').classList).remove.apply(_f, DIGIT_TO_NAME);
        this.digits.get('h1').classList.add(DIGIT_TO_NAME[parseInt(hour[0])]);
        this.digits.get('h2').classList.add(DIGIT_TO_NAME[parseInt(hour[1])]);
        this.digits.get('m1').classList.add(DIGIT_TO_NAME[parseInt(minute[0])]);
        this.digits.get('m2').classList.add(DIGIT_TO_NAME[parseInt(minute[1])]);
        this.digits.get('s1').classList.add(DIGIT_TO_NAME[parseInt(second[0])]);
        this.digits.get('s2').classList.add(DIGIT_TO_NAME[parseInt(second[1])]);
    };
    /**
     * Blink the hour digit in interface.
     *
     * When mode is clicked one time
     */
    Watch.prototype.blinkHour = function () {
        this.digits.get('h1').classList.add('blink');
        this.digits.get('h2').classList.add('blink');
        this.digits.get('m1').classList.remove('blink');
        this.digits.get('m2').classList.remove('blink');
    };
    ;
    /**
     * Blink the minute digit in interface.
     *
     * When mode is clicked two times
     */
    Watch.prototype.blinkMinute = function () {
        this.digits.get('m1').classList.add('blink');
        this.digits.get('m2').classList.add('blink');
        this.digits.get('h1').classList.remove('blink');
        this.digits.get('h2').classList.remove('blink');
    };
    ;
    /**
     * Unblink all digits of the watch
     */
    Watch.prototype.unBlink = function () {
        this.digits.get('m1').classList.remove('blink');
        this.digits.get('m2').classList.remove('blink');
        this.digits.get('h1').classList.remove('blink');
        this.digits.get('h2').classList.remove('blink');
    };
    ;
    /**
     * Reset the watch
     */
    Watch.prototype.reset = function () {
        this.mode = MODE_DISPLAY.LIGHT;
        var element = document.getElementById("watch-".concat(this.index));
        element.className = "watch light";
        this.digits.get('displayButton').innerHTML = this.time.format;
    };
    /**
     * Get the initial position of the watch
     * @returns The initial coordinate of the watch
     */
    Watch.prototype.getCoordinate = function () {
        var element = document.getElementById("watch-".concat(this.index));
        var rect = element.getBoundingClientRect();
        var x = rect.x;
        var y = rect.y;
        this.point = new Point(x, y);
        return this.point;
    };
    // for rotation around a point
    /**
     * Initialize animation for the watch
     */
    Watch.prototype.initAnimation = function () {
        var element = document.getElementById("watch-".concat(this.index));
        element.style.position = 'absolute',
            element.style.top = '0px';
        element.style.left = '0px';
        this.getCoordinate();
    };
    /**
     * Rotate the watch around a center point with step in rad
     * @param x - Coordinate X of center
     * @param y - Coordinate Y of center
     * @param rad - Rotation step (in rad)
     */
    Watch.prototype.rotate = function (x, y, rad) {
        var centerPoint = new Point(x, y);
        var vectorPoint = this.point.subtract(centerPoint);
        var animation = new Animation();
        var newPositionMatrix = animation.rotate(vectorPoint.toPoint(), rad);
        var newPositionVector = newPositionMatrix.coord.map(function (value) { return value[0]; });
        var newX = newPositionVector[0] + x;
        var newY = newPositionVector[1] + y;
        this.point = new Point(newX, newY);
        var element = document.getElementById("watch-".concat(this.index));
        element.style.left = "".concat(this.point.x, "px");
        element.style.top = "".concat(this.point.y, "px");
    };
    /**
     * Rotate the watch around itself
     */
    Watch.prototype.rotateAroundSelf = function () {
        this.initDeg++;
        var element = document.getElementById("watch-".concat(this.index));
        element.style.transform = "rotate(".concat(this.initDeg % 360, "deg)");
    };
    /**
     * Scale the watch
     * @param x - Scale x
     * @param y - Scale y
     */
    Watch.prototype.scale = function (x, y) {
        this.initScale = this.initScale.add(new Point(x, y)).toPoint();
        var element = document.getElementById("watch-".concat(this.index));
        element.style.transform = "scale(".concat(this.initScale.x, ", ").concat(this.initScale.y, ")");
    };
    /**
     * Start the rotation around self for the watch
     */
    Watch.prototype.startRotateAroundSelf = function () {
        var that = this;
        this.timerAnimationRotateAroundSelf = setInterval(function () {
            that.rotateAroundSelf();
        }, 500);
    };
    /**
     * Start the rotation around the bouding screen for the watch
     */
    Watch.prototype.startRotate = function () {
        var that = this;
        var element = document.getElementById("watch-".concat(this.index));
        var width = element.getBoundingClientRect().width;
        var height = element.getBoundingClientRect().height;
        var x = that.point.x + width / 2;
        var y = that.point.y + height / 2;
        this.timerAnimationRotate = setInterval(function () {
            that.rotate(x, y, 0.05);
        }, 500);
    };
    /**
     * Reset all animation. Stop all animation and make the watch return to its initial position
     */
    Watch.prototype.resetAnimation = function () {
        clearInterval(this.timerAnimationRotate);
        clearInterval(this.timerAnimationRotateAroundSelf);
        var element = document.getElementById("watch-".concat(this.index));
        element.style.position = 'relative',
            element.style.top = '0px';
        element.style.left = '0px';
        element.style.rotate = '';
        element.style.transform = 'none';
        this.getCoordinate();
    };
    return Watch;
}());
export { Watch };
