import { Matrix } from "./src/class/matrix.class";
import { Vector } from "./src/class/vector.class";
var Animation = /** @class */ (function () {
    function Animation() {
    }
    /**
     * Translate the object
     * @param start - Start position
     * @param moving - Distance of translation (in coordinates)
     * @returns New position of object after translation as type Matrix
     */
    Animation.prototype.translate = function (start, moving) {
        var startVector = new Vector(start);
        var startSize = startVector.toArray().length;
        var startMatrix_ = new Matrix(startSize, startSize);
        var startMatrix = startMatrix_.fromVectorToMatrix(startVector);
        var movingVector = new Vector(moving);
        var movingSize = movingVector.toArray().length;
        var movingMatrix_ = new Matrix(movingSize, movingSize);
        var movingMatrix = movingMatrix_.fromVectorToTranslationMatrix(movingVector);
        return movingMatrix.multiple(startMatrix);
    };
    /**
     * Rotate the object
     * @param start - Start position
     * @param rad - Angle of rotation
     * @returns New position of object after rotation as type Matrix
     */
    Animation.prototype.rotate = function (start, rad) {
        var startVector = new Vector(start);
        var startSize = startVector.toArray().length;
        var startMatrix_ = new Matrix(startSize, startSize);
        var startMatrix = startMatrix_.fromVectorToMatrix(startVector);
        var rotationMatrix_ = new Matrix(startSize, startSize);
        var rotationMatrix = rotationMatrix_.toRotationMatrix(rad, 0, 1);
        return rotationMatrix.multiple(startMatrix);
    };
    /**
     * Scale the dimension of the object
     * @param start - Start position
     * @param scale - Factor of scaling
     * @returns New coordinates of object after scaling as type Matrix
     */
    Animation.prototype.scale = function (start, scale) {
        var startVector = new Vector(start);
        var startSize = startVector.toArray().length;
        var startMatrix_ = new Matrix(startSize, startSize);
        var startMatrix = startMatrix_.fromVectorToMatrix(startVector);
        var scaleVector = new Vector(scale);
        var scaleLength = scaleVector.toArray().length;
        var scaleMatrix_ = new Matrix(scaleLength, scaleLength);
        var scaleMatrix = scaleMatrix_.toScaleMatrix(scaleVector);
        return scaleMatrix.multiple(startMatrix);
    };
    return Animation;
}());
export { Animation };
