import { Vector } from "./vector.class";
var Point = /** @class */ (function () {
    function Point(x, y) {
        this.x = x;
        this.y = y;
    }
    /**
     * Subtract to the coordinates of point in parameter
     * @param point - Point to be subtracted to
     * @returns The result of subtraction between 2 points
     */
    Point.prototype.subtract = function (point) {
        var p = new Point(0, 0);
        var v = new Vector(p);
        v.x = this.x - point.x;
        v.y = this.y - point.y;
        return v;
    };
    /**
     * Add with the coordinates of point in parameter
     * @param point - Point to be added with
     * @returns The result of addition between 2 points
     */
    Point.prototype.add = function (point) {
        var p = new Point(0, 0);
        var v = new Vector(p);
        v.x = this.x + point.x;
        v.y = this.y + point.y;
        return v;
    };
    return Point;
}());
export { Point };
