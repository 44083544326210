var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MODE } from "../../constant";
import { Button } from "./button.class";
var ButtonIncrease = /** @class */ (function (_super) {
    __extends(ButtonIncrease, _super);
    function ButtonIncrease() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /** @override */
    ButtonIncrease.prototype.onPress = function (time, watch) {
        switch (time.mode) {
            case MODE.DISABLED:
                break;
            case MODE.EDIT_HOUR:
                time.increaseHour();
                watch.update();
                break;
            default:
                time.increaseMinute();
                watch.update();
                break;
        }
    };
    return ButtonIncrease;
}(Button));
export { ButtonIncrease };
