/**
 * Mode of the watch
 */
export var MODE;
(function (MODE) {
    MODE[MODE["DISABLED"] = 0] = "DISABLED";
    MODE[MODE["EDIT_HOUR"] = 1] = "EDIT_HOUR";
    MODE[MODE["EDIT_MINUTE"] = 2] = "EDIT_MINUTE"; // edit mode (minute)
})(MODE || (MODE = {}));
/**
 * Time format
 */
export var FORMAT;
(function (FORMAT) {
    FORMAT["GLOBAL"] = "24H";
    FORMAT["LOCAL"] = "A/P"; // time displayed in format AM/PM
})(FORMAT || (FORMAT = {}));
/**
 * Type of local time format
 */
export var LOCAL_TYPE;
(function (LOCAL_TYPE) {
    LOCAL_TYPE["AM"] = "AM";
    LOCAL_TYPE["PM"] = "PM"; // afternoon time
})(LOCAL_TYPE || (LOCAL_TYPE = {}));
/**
 * Display mode of the watch
 */
export var MODE_DISPLAY;
(function (MODE_DISPLAY) {
    MODE_DISPLAY["LIGHT"] = "light";
    MODE_DISPLAY["DARK"] = "dark"; // dark mode
})(MODE_DISPLAY || (MODE_DISPLAY = {}));
export var DIGIT_TO_NAME = 'zero one two three four five six seven eight nine'.split(' ');
