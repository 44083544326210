import { FORMAT, LOCAL_TYPE, MODE } from "../../constant";
var Time = /** @class */ (function () {
    function Time(timeZone) {
        this.timeZone = timeZone;
        this.mode = MODE.DISABLED,
            this.format = FORMAT.GLOBAL;
        this.isPassedTimezone = false;
        this.reset();
        this.localType = this.format === FORMAT.GLOBAL ? (this.hour >= 12 ? LOCAL_TYPE.PM : LOCAL_TYPE.AM) : LOCAL_TYPE.PM;
    }
    /**
     * Increase hour based on current time format (12h/24h)
     * Used to set custom time for the watch
     */
    Time.prototype.increaseHour = function () {
        if (this.format === FORMAT.GLOBAL) {
            this.hour++;
            if (this.hour >= 24) {
                this.hour = 0;
            }
        }
        else {
            this.hour++;
            if (this.localType === LOCAL_TYPE.AM) {
                if (this.hour === 12) {
                    this.localType = LOCAL_TYPE.PM;
                }
                this.hour = this.hour === 12 ? 12 : this.hour % 12;
            }
            else {
                if (this.hour === 12) {
                    this.localType = LOCAL_TYPE.AM;
                }
                this.hour = this.hour === 12 ? 12 : this.hour % 12;
            }
        }
    };
    /**
     * Increase minute
     * Used to set custom time for the watch
     */
    Time.prototype.increaseMinute = function () {
        this.minute++;
        if (this.minute >= 60) {
            this.minute = 0;
        }
    };
    /**
     * Change time format of the watch : 24H <-> 12H
     */
    Time.prototype.changeFormat = function () {
        switch (this.format) {
            case FORMAT.GLOBAL:
                var now = new Date();
                now.setHours(this.hour, this.minute, this.second);
                var nowStr = now.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
                var _a = nowStr.split(':').map(function (value) { return parseInt(value); }), hour = _a[0], minute = _a[1], second = _a[2];
                this.localType = nowStr.split(' ')[1];
                this.hour = hour;
                this.minute = minute;
                this.second = second;
                this.format = FORMAT.LOCAL;
                break;
            default:
                var hour_ = void 0;
                if (this.localType === LOCAL_TYPE.AM) {
                    hour_ = this.hour === 12 ? 0 : this.hour;
                }
                else {
                    hour_ = this.hour === 12 ? 12 : this.hour + 12;
                }
                this.hour = hour_;
                this.format = FORMAT.GLOBAL;
                break;
        }
    };
    ;
    /**
     * Returns the time of watch after changing time format
     *
     * @returns The current hour of the watch after changing time format
     */
    Time.prototype.transformHourLocalToGlobal = function () {
        if (this.format === FORMAT.LOCAL) {
            if (this.localType === LOCAL_TYPE.AM) {
                return this.hour === 12 ? 0 : this.hour;
            }
            else {
                return this.hour === 12 ? 12 : this.hour + 12;
            }
        }
        else {
            return this.hour;
        }
    };
    /**
     * Update the date for the watch
     *
     * @param date - New date to update
     */
    Time.prototype.setDate = function (date) {
        var nowStr = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: this.format === FORMAT.LOCAL });
        var _a = nowStr.split(':').map(function (value) { return parseInt(value); }), hour = _a[0], minute = _a[1], second = _a[2];
        this.hour = hour === 24 ? 0 : hour;
        this.minute = minute;
        this.second = second;
        if (this.format === FORMAT.LOCAL) {
            this.localType = nowStr.split(' ')[1];
        }
    };
    /**
     * Start the timer
     * Update time of the watch each second
     * @param callback - Function to call in the interval
     */
    Time.prototype.start = function (callback) {
        var _this = this;
        var that = this;
        this.timer = setInterval(function () {
            var now = new Date();
            var hour = that.transformHourLocalToGlobal();
            var minute = _this.minute;
            var second = _this.second;
            now.setHours(hour, minute, second);
            now = new Date(now.getTime() + 1000);
            _this.setDate(now);
            callback();
        }, 1000);
    };
    ;
    /**
     * Reset time of the watch
     */
    Time.prototype.reset = function () {
        var now = new Date();
        var nowStr = now.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: this.format === FORMAT.LOCAL, timeZone: this.timeZone });
        var _a = nowStr.split(':').map(function (value) { return parseInt(value); }), hour = _a[0], minute = _a[1], second = _a[2];
        this.hour = hour === 24 ? 0 : hour;
        this.minute = minute;
        this.second = second;
        this.format = FORMAT.GLOBAL;
    };
    /**
     * Returns time as type string for displaying
     *
     * @returns the display string of time
     */
    Time.prototype.display = function () {
        var hour = this.hour < 10 ? "0".concat(this.hour) : this.hour.toString();
        var minute = this.minute < 10 ? "0".concat(this.minute) : this.minute.toString();
        var second = this.second < 10 ? "0".concat(this.second) : this.second.toString();
        if (this.format === FORMAT.GLOBAL) {
            return "".concat(hour, ":").concat(minute, ":").concat(second);
        }
        else {
            return "".concat(hour, ":").concat(minute, ":").concat(second, " ").concat(this.localType);
        }
    };
    return Time;
}());
export { Time };
