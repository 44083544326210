import { listTimeZone } from "../../constant/listTimezone.constant";
import { Watch } from "./watch.class";
var WatchManager = /** @class */ (function () {
    function WatchManager() {
        this.watches = [];
        this.nbWatch = 0;
        this.listTimeZone = listTimeZone;
        this.selectedTimeZone = '';
        this.init();
    }
    /**
     * Add a new watch with a selected timezone
     */
    WatchManager.prototype.addWatch = function () {
        if (this.selectedTimeZone) {
            var watch = new Watch(this.selectedTimeZone, this.nbWatch);
            watch.run();
            this.watches.push(watch);
            this.nbWatch++;
        }
    };
    /**
     * Select a timezone in the list
     */
    WatchManager.prototype.select = function () {
        var selectedBox = document.getElementById("selector-timezone");
        var index = selectedBox.selectedIndex;
        var selectedValue = selectedBox.options[index].value;
        this.selectedTimeZone = selectedValue;
    };
    /**
     * Init all elements of the watch
     */
    WatchManager.prototype.init = function () {
        var listWatchesElement = document.createElement('div');
        listWatchesElement.id = 'list-watches';
        var selectElement = document.createElement('select');
        selectElement.id = 'selector-timezone';
        selectElement.addEventListener('change', this.select.bind(this));
        for (var _i = 0, _a = this.listTimeZone; _i < _a.length; _i++) {
            var timezone = _a[_i];
            var optionElement = document.createElement('option');
            optionElement.value = timezone;
            optionElement.innerHTML = timezone || 'Select a timezone';
            selectElement.appendChild(optionElement);
        }
        var buttonElement = document.createElement('button');
        buttonElement.innerHTML = 'Add';
        buttonElement.addEventListener('click', this.addWatch.bind(this));
        var containerElement = document.createElement('div');
        containerElement.className = 'container';
        containerElement.appendChild(selectElement);
        containerElement.appendChild(buttonElement);
        var root = document.getElementById('root');
        root.appendChild(containerElement);
        root.appendChild(listWatchesElement);
    };
    return WatchManager;
}());
export { WatchManager };
