var Button = /** @class */ (function () {
    function Button() {
    }
    /**
     * Callback when a watch's button is clicked
     *
     * @param time - time of the watch
     * @param watch - the watch needs to be handled
     *
     * @virtual
     */
    Button.prototype.onPress = function (time, watch) { };
    return Button;
}());
export { Button };
